export const AccountNotifications = () => import('../../components/account-notifications.vue' /* webpackChunkName: "components/account-notifications" */).then(c => wrapFunctional(c.default || c))
export const AccountSecurity = () => import('../../components/account-security.vue' /* webpackChunkName: "components/account-security" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbsLine = () => import('../../components/breadcrumbsLine.vue' /* webpackChunkName: "components/breadcrumbs-line" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbsTitle = () => import('../../components/breadcrumbsTitle.vue' /* webpackChunkName: "components/breadcrumbs-title" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const CompanyAccountBilling = () => import('../../components/company/account-billing.vue' /* webpackChunkName: "components/company-account-billing" */).then(c => wrapFunctional(c.default || c))
export const CompanyAccountContacts = () => import('../../components/company/account-contacts.vue' /* webpackChunkName: "components/company-account-contacts" */).then(c => wrapFunctional(c.default || c))
export const CompanyAccountMenu = () => import('../../components/company/account-menu.vue' /* webpackChunkName: "components/company-account-menu" */).then(c => wrapFunctional(c.default || c))
export const CompanyAccountProfile = () => import('../../components/company/account-profile.vue' /* webpackChunkName: "components/company-account-profile" */).then(c => wrapFunctional(c.default || c))
export const FreelancerAccountBilling = () => import('../../components/freelancer/account-billing.vue' /* webpackChunkName: "components/freelancer-account-billing" */).then(c => wrapFunctional(c.default || c))
export const FreelancerAccountContacts = () => import('../../components/freelancer/account-contacts.vue' /* webpackChunkName: "components/freelancer-account-contacts" */).then(c => wrapFunctional(c.default || c))
export const FreelancerAccountMenu = () => import('../../components/freelancer/account-menu.vue' /* webpackChunkName: "components/freelancer-account-menu" */).then(c => wrapFunctional(c.default || c))
export const FreelancerAccountProfile = () => import('../../components/freelancer/account-profile.vue' /* webpackChunkName: "components/freelancer-account-profile" */).then(c => wrapFunctional(c.default || c))
export const FreelancerAccountTeam = () => import('../../components/freelancer/account-team.vue' /* webpackChunkName: "components/freelancer-account-team" */).then(c => wrapFunctional(c.default || c))
export const FreelancerFilterCard = () => import('../../components/freelancer/filter-card.vue' /* webpackChunkName: "components/freelancer-filter-card" */).then(c => wrapFunctional(c.default || c))
export const JobsBidCard = () => import('../../components/jobs/bid-card.vue' /* webpackChunkName: "components/jobs-bid-card" */).then(c => wrapFunctional(c.default || c))
export const JobsFilterCard = () => import('../../components/jobs/filter-card.vue' /* webpackChunkName: "components/jobs-filter-card" */).then(c => wrapFunctional(c.default || c))
export const JobsJobCard = () => import('../../components/jobs/job-card.vue' /* webpackChunkName: "components/jobs-job-card" */).then(c => wrapFunctional(c.default || c))
export const JobsSearchResults = () => import('../../components/jobs/search-results.vue' /* webpackChunkName: "components/jobs-search-results" */).then(c => wrapFunctional(c.default || c))
export const NavigationFooter = () => import('../../components/navigation/footer.vue' /* webpackChunkName: "components/navigation-footer" */).then(c => wrapFunctional(c.default || c))
export const NavigationHeader = () => import('../../components/navigation/header.vue' /* webpackChunkName: "components/navigation-header" */).then(c => wrapFunctional(c.default || c))
export const SocialShareDropdown = () => import('../../components/social/social-share-dropdown.vue' /* webpackChunkName: "components/social-share-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UsersContractorCard = () => import('../../components/users/contractorCard.vue' /* webpackChunkName: "components/users-contractor-card" */).then(c => wrapFunctional(c.default || c))
export const UsersOnlineAvatar = () => import('../../components/users/onlineAvatar.vue' /* webpackChunkName: "components/users-online-avatar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
