import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44178eba = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _6fb073ef = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "pages/content/index" */))
const _6482fc54 = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _392f098a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _33d8253a = () => interopDefault(import('../pages/for-clients/index.vue' /* webpackChunkName: "pages/for-clients/index" */))
const _d1e36d0a = () => interopDefault(import('../pages/for-experts/index.vue' /* webpackChunkName: "pages/for-experts/index" */))
const _649d3f83 = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _89afbf08 = () => interopDefault(import('../pages/inbox/index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _4b631236 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _75146c1c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _69859f38 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _4f2a9156 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _26298b8c = () => interopDefault(import('../pages/account/billing.vue' /* webpackChunkName: "pages/account/billing" */))
const _5cce8bc8 = () => interopDefault(import('../pages/account/billing_callback.vue' /* webpackChunkName: "pages/account/billing_callback" */))
const _3530a551 = () => interopDefault(import('../pages/account/confirm.vue' /* webpackChunkName: "pages/account/confirm" */))
const _43b8c492 = () => interopDefault(import('../pages/account/contacts.vue' /* webpackChunkName: "pages/account/contacts" */))
const _7f3660b9 = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _184ebada = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _2b40abba = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _e72985c8 = () => interopDefault(import('../pages/account/team.vue' /* webpackChunkName: "pages/account/team" */))
const _9499179e = () => interopDefault(import('../pages/forgot/confirm.vue' /* webpackChunkName: "pages/forgot/confirm" */))
const _5a271ed6 = () => interopDefault(import('../pages/inbox/notifications/index.vue' /* webpackChunkName: "pages/inbox/notifications/index" */))
const _f81a9336 = () => interopDefault(import('../pages/jobs/bids/index.vue' /* webpackChunkName: "pages/jobs/bids/index" */))
const _af5b9d04 = () => interopDefault(import('../pages/jobs/favorites/index.vue' /* webpackChunkName: "pages/jobs/favorites/index" */))
const _13364832 = () => interopDefault(import('../pages/jobs/submit/index.vue' /* webpackChunkName: "pages/jobs/submit/index" */))
const _42083456 = () => interopDefault(import('../pages/signup/client.vue' /* webpackChunkName: "pages/signup/client" */))
const _95a422e6 = () => interopDefault(import('../pages/signup/freelancer.vue' /* webpackChunkName: "pages/signup/freelancer" */))
const _cf73dc14 = () => interopDefault(import('../pages/stats/earnings.vue' /* webpackChunkName: "pages/stats/earnings" */))
const _57999c9c = () => interopDefault(import('../pages/stats/spendings.vue' /* webpackChunkName: "pages/stats/spendings" */))
const _25b9a622 = () => interopDefault(import('../pages/stats/transactions.vue' /* webpackChunkName: "pages/stats/transactions" */))
const _3e87c32e = () => interopDefault(import('../pages/workload/contracts/index.vue' /* webpackChunkName: "pages/workload/contracts/index" */))
const _2058f4e4 = () => interopDefault(import('../pages/workload/jobs/index.vue' /* webpackChunkName: "pages/workload/jobs/index" */))
const _8ee37ad4 = () => interopDefault(import('../pages/workload/tasks/index.vue' /* webpackChunkName: "pages/workload/tasks/index" */))
const _c09700f2 = () => interopDefault(import('../pages/workload/tasks/submit/index.vue' /* webpackChunkName: "pages/workload/tasks/submit/index" */))
const _a9266df6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _655a9b20 = () => interopDefault(import('../pages/workload/tasks/submit/_tuid.vue' /* webpackChunkName: "pages/workload/tasks/submit/_tuid" */))
const _3badb531 = () => interopDefault(import('../pages/inbox/notifications/_page.vue' /* webpackChunkName: "pages/inbox/notifications/_page" */))
const _269809fe = () => interopDefault(import('../pages/jobs/bids/_page.vue' /* webpackChunkName: "pages/jobs/bids/_page" */))
const _1113761a = () => interopDefault(import('../pages/jobs/favorites/_page.vue' /* webpackChunkName: "pages/jobs/favorites/_page" */))
const _15374e1a = () => interopDefault(import('../pages/jobs/submit/_juid.vue' /* webpackChunkName: "pages/jobs/submit/_juid" */))
const _416aaec6 = () => interopDefault(import('../pages/workload/contracts/_cuid/index.vue' /* webpackChunkName: "pages/workload/contracts/_cuid/index" */))
const _bd95c64a = () => interopDefault(import('../pages/workload/tasks/_filter.vue' /* webpackChunkName: "pages/workload/tasks/_filter" */))
const _2044a0c2 = () => interopDefault(import('../pages/workload/tasks/_tuid/index.vue' /* webpackChunkName: "pages/workload/tasks/_tuid/index" */))
const _6eb4febe = () => interopDefault(import('../pages/workload/jobs/_juid/bids.vue' /* webpackChunkName: "pages/workload/jobs/_juid/bids" */))
const _6df9d2a7 = () => interopDefault(import('../pages/content/_slug.vue' /* webpackChunkName: "pages/content/_slug" */))
const _22e25f19 = () => interopDefault(import('../pages/contractors/_filter.vue' /* webpackChunkName: "pages/contractors/_filter" */))
const _322183f2 = () => interopDefault(import('../pages/for-clients/_slug.vue' /* webpackChunkName: "pages/for-clients/_slug" */))
const _d550af9a = () => interopDefault(import('../pages/for-experts/_slug.vue' /* webpackChunkName: "pages/for-experts/_slug" */))
const _5bd6f70a = () => interopDefault(import('../pages/jobs/_filter.vue' /* webpackChunkName: "pages/jobs/_filter" */))
const _6fe1f5cf = () => interopDefault(import('../pages/contractors/_uuid/details.vue' /* webpackChunkName: "pages/contractors/_uuid/details" */))
const _5fa704c8 = () => interopDefault(import('../pages/jobs/_juid/apply.vue' /* webpackChunkName: "pages/jobs/_juid/apply" */))
const _4702811c = () => interopDefault(import('../pages/jobs/_juid/details.vue' /* webpackChunkName: "pages/jobs/_juid/details" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _44178eba,
    name: "account___en"
  }, {
    path: "/content",
    component: _6fb073ef,
    name: "content___en"
  }, {
    path: "/contractors",
    component: _6482fc54,
    name: "contractors___en"
  }, {
    path: "/dashboard",
    component: _392f098a,
    name: "dashboard___en"
  }, {
    path: "/for-clients",
    component: _33d8253a,
    name: "for-clients___en"
  }, {
    path: "/for-experts",
    component: _d1e36d0a,
    name: "for-experts___en"
  }, {
    path: "/forgot",
    component: _649d3f83,
    name: "forgot___en"
  }, {
    path: "/inbox",
    component: _89afbf08,
    name: "inbox___en"
  }, {
    path: "/jobs",
    component: _4b631236,
    name: "jobs___en"
  }, {
    path: "/login",
    component: _75146c1c,
    name: "login___en"
  }, {
    path: "/signup",
    component: _69859f38,
    name: "signup___en"
  }, {
    path: "/stats",
    component: _4f2a9156,
    name: "stats___en"
  }, {
    path: "/account/billing",
    component: _26298b8c,
    name: "account-billing___en"
  }, {
    path: "/account/billing_callback",
    component: _5cce8bc8,
    name: "account-billing_callback___en"
  }, {
    path: "/account/confirm",
    component: _3530a551,
    name: "account-confirm___en"
  }, {
    path: "/account/contacts",
    component: _43b8c492,
    name: "account-contacts___en"
  }, {
    path: "/account/notifications",
    component: _7f3660b9,
    name: "account-notifications___en"
  }, {
    path: "/account/password",
    component: _184ebada,
    name: "account-password___en"
  }, {
    path: "/account/profile",
    component: _2b40abba,
    name: "account-profile___en"
  }, {
    path: "/account/team",
    component: _e72985c8,
    name: "account-team___en"
  }, {
    path: "/forgot/confirm",
    component: _9499179e,
    name: "forgot-confirm___en"
  }, {
    path: "/inbox/notifications",
    component: _5a271ed6,
    name: "inbox-notifications___en"
  }, {
    path: "/jobs/bids",
    component: _f81a9336,
    name: "jobs-bids___en"
  }, {
    path: "/jobs/favorites",
    component: _af5b9d04,
    name: "jobs-favorites___en"
  }, {
    path: "/jobs/submit",
    component: _13364832,
    name: "jobs-submit___en"
  }, {
    path: "/signup/client",
    component: _42083456,
    name: "signup-client___en"
  }, {
    path: "/signup/freelancer",
    component: _95a422e6,
    name: "signup-freelancer___en"
  }, {
    path: "/stats/earnings",
    component: _cf73dc14,
    name: "stats-earnings___en"
  }, {
    path: "/stats/spendings",
    component: _57999c9c,
    name: "stats-spendings___en"
  }, {
    path: "/stats/transactions",
    component: _25b9a622,
    name: "stats-transactions___en"
  }, {
    path: "/workload/contracts",
    component: _3e87c32e,
    name: "workload-contracts___en"
  }, {
    path: "/workload/jobs",
    component: _2058f4e4,
    name: "workload-jobs___en"
  }, {
    path: "/workload/tasks",
    component: _8ee37ad4,
    name: "workload-tasks___en"
  }, {
    path: "/workload/tasks/submit",
    component: _c09700f2,
    name: "workload-tasks-submit___en"
  }, {
    path: "/",
    component: _a9266df6,
    name: "index___en"
  }, {
    path: "/workload/tasks/submit/:tuid",
    component: _655a9b20,
    name: "workload-tasks-submit-tuid___en"
  }, {
    path: "/inbox/notifications/:page",
    component: _3badb531,
    name: "inbox-notifications-page___en"
  }, {
    path: "/jobs/bids/:page",
    component: _269809fe,
    name: "jobs-bids-page___en"
  }, {
    path: "/jobs/favorites/:page",
    component: _1113761a,
    name: "jobs-favorites-page___en"
  }, {
    path: "/jobs/submit/:juid",
    component: _15374e1a,
    name: "jobs-submit-juid___en"
  }, {
    path: "/workload/contracts/:cuid",
    component: _416aaec6,
    name: "workload-contracts-cuid___en"
  }, {
    path: "/workload/tasks/:filter",
    component: _bd95c64a,
    name: "workload-tasks-filter___en"
  }, {
    path: "/workload/tasks/:tuid",
    component: _2044a0c2,
    name: "workload-tasks-tuid___en"
  }, {
    path: "/workload/jobs/:juid/bids",
    component: _6eb4febe,
    name: "workload-jobs-juid-bids___en"
  }, {
    path: "/content/:slug",
    component: _6df9d2a7,
    name: "content-slug___en"
  }, {
    path: "/contractors/:filter",
    component: _22e25f19,
    name: "contractors-filter___en"
  }, {
    path: "/for-clients/:slug?",
    component: _322183f2,
    name: "for-clients-slug___en"
  }, {
    path: "/for-experts/:slug?",
    component: _d550af9a,
    name: "for-experts-slug___en"
  }, {
    path: "/jobs/:filter",
    component: _5bd6f70a,
    name: "jobs-filter___en"
  }, {
    path: "/contractors/:uuid/details",
    component: _6fe1f5cf,
    name: "contractors-uuid-details___en"
  }, {
    path: "/jobs/:juid/apply",
    component: _5fa704c8,
    name: "jobs-juid-apply___en"
  }, {
    path: "/jobs/:juid/details",
    component: _4702811c,
    name: "jobs-juid-details___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
